import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import GraphImg from 'graphcms-image';
import { device } from 'components/device';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props =>
    props.resumed ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'};
  padding: 0 0 3.125rem 0;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    padding: 2.8125rem 1.25rem;
  }

  @media ${device.tablet} {
    padding: ${props => (props.resumed ? 0 : '2.8125rem 0')};
    padding-top: ${props =>
      props.paddingTopMobile
        ? props.paddingTopMobile
        : props.resumed && '2.8125rem'};
  }

  .image {
    width: ${props => (props.resumed ? '100%' : '570px')};
    height: 380px;
    background-color: #c4c4c4;
    margin-right: 5.3125rem;

    @media ${device.laptop} {
      display: none;
    }
  }
`;

const Title = styled.h2`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 30px;
  color: #231f20;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
    font-size: 1.125rem;
  }
`;

const BoxImage = styled.div`
  width: ${props => (props.resumed ? '100%' : '50%')};

  @media ${device.laptop} {
    width: ${props => props.imgAdjustWidthmobile && props.imgAdjustWidthmobile};
    margin-bottom: ${props =>
      props.marginbottomImgMobile && props.marginbottomImgMobile};
  }

  .ImgComponent {
    width: ${props =>
      props.imgAdjustWidth ? props.imgAdjustWidth : `${props.imageWidth}px`};
    height: ${props =>
      props.ImgAdjustHeight ? props.ImgAdjustHeight : `${props.imageHeight}px`};

    @media ${device.laptop} {
      display: flex;
      width: 100%;
    }
    @media ${device.mobile} {
      height: 223px;
    }
  }
`;

const Content = styled.div`
  width: 96%;
  margin-top: 4.7rem;
  margin-left: ${props =>
    props.contentMarginLeftDesk
      ? props.contentMarginLeftDesk
      : !props.resumed && '5.3125rem'};

  @media ${device.laptop} {
    margin-top: 0;
    margin-left: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  ${props =>
    props.resumed &&
    `
    > ul {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      list-style: none;
      column-gap: 1.8125rem;
      margin: 0;

      @media ${device.tablet}{
        display: flex;
        flex-direction: column;
        margin-left: 0;
      }

      li:nth-child(1){
        color: red;
      }

      li{
        @media ${device.tablet}{
          margin-bottom: 2rem;
        }

        p{
          width: ${props.textWidthDesk ? props.textWidthDesk : '85%'};
          margin: 0;
          font-size: 1rem;
          line-height: 1.625rem;
          font-weight: 400;

          @media ${device.tablet}{
            width: 100%;
            font-size: 0.875rem;
          }
        }
      }
    }

 `}

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 26px;
    color: #231f20;
    margin-bottom: 2rem;
    width: ${props => (props.textWidthDesk ? props.textWidthDesk : '85%')};
    max-width: ${props => props.textMaxWidth && props.textMaxWidth};

    @media ${device.laptop} {
      margin-left: 0;
      font-size: 0.875rem;
    }

    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

const OurStory = ({
  markdown,
  asset,
  fragments,
  resumed,
  contentMarginLeftDesk,
  textWidthDesk,
  textMaxWidth,
  imgAdjustWidth,
  ImgAdjustHeight,
  imgAdjustWidthmobile,
  paddingTopMobile,
  marginbottomImgMobile,
}) => {
  const title = fragments && fragments[0].singletexts;

  return (
    <Container resumed={resumed} paddingTopMobile={paddingTopMobile}>
      <BoxImage
        imgAdjustWidthmobile={imgAdjustWidthmobile}
        marginbottomImgMobile={marginbottomImgMobile}
        resumed={resumed}
        imageWidth={imgAdjustWidth || (asset && asset.width)}
        imageHeight={ImgAdjustHeight || (asset && asset.height)}
      >
        <Title>{title}</Title>
        {asset ? (
          <GraphImg
            image={asset}
            className="ImgComponent"
            alt="ImgComponent"
            fadeIn={true}
            withWebp={true}
          />
        ) : (
          <div className="image" />
        )}
      </BoxImage>
      <Content
        resumed={resumed}
        paddingTopMobile={paddingTopMobile}
        contentMarginLeftDesk={contentMarginLeftDesk}
        textWidthDesk={textWidthDesk}
        textMaxWidth={textMaxWidth}
      >
        <ReactMarkdown children={markdown || '-'} />
      </Content>
    </Container>
  );
};

export default OurStory;
