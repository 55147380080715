// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import { GridPageWhoWeAre } from 'components/gridPageWhoWeAre/index';
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import OurStory from 'components/ourStory';
import CharitySocietyAboutUs from 'components/home/charitySocietyAboutUs';
import { SubMenu } from 'components/subMenu/index';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';

// Context
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  .featuredMkt {
    section {
      padding-bottom: 2.5rem;

      @media ${device.laptop} {
        margin-top: 3rem;
      }

      @media ${device.tablet} {
        margin-top: 0;
      }

      @media ${device.mobile} {
        margin-bottom: 1.875rem;
      }

      > :first-child {
        display: flex;

        > div {
          margin: 0;
        }

        @media ${device.laptop} {
          display: none;
        }
      }

      > :last-child {
        > :first-child {
          h1 {
            width: 85%;
          }

          p {
            color: #8f9194;
            margin-left: 0;
          }

          @media ${device.laptop} {
            padding-top: 0;
            height: auto;
          }

          @media ${device.mobile} {
            margin-top: 0;
            padding-bottom: 0;
          }
        }

        ${'' /* BoxImages */}
        > :last-child {
          width: 86%;
          height: 380px;

          > div {
            width: 100%;
          }

          @media ${device.laptop} {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

export const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  flex-direction: ${props => props.direction};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottomDesk};
  margin-bottom: ${props => props.paddingPartIndex && '4.375rem'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    border-bottom: ${props => props.notPaddingTop && '1px solid #C6C8CC'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  ${props =>
    props.notFirstChild
      ? null
      : css`
          :first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media ${device.laptop} {
              margin-bottom: 0;
            }

            @media ${device.tablet} {
              margin-bottom: ${props =>
                props.isCDIBelaVista ? '0' : '1.875rem'};
            }

            @media ${device.mobile} {
              margin-bottom: 0;
            }
          }
        `}

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    margin-bottom: ${props => props.paddingPartIndex && '4.375rem'};
    width: ${props => props.widthLap};
    flex-direction: column;
    margin-top: 0;
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props =>
      (props.notPaddingTop && '30px') || props.paddingBottom};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC !important'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.mobile} {
    margin-bottom: ${props => props.paddingPartIndex && '0'};
  }
`;

export const GridLarge = styled.div`
  padding: ${props =>
    !props.codeOfConduct &&
    `
    4.375rem 0
  `};
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background-color: ${props => props.backgroundColor};
  margin-bottom: ${props =>
    props.marginBottomGridLarge && props.marginBottomGridLarge};

  ${props =>
    props.codeOfConduct &&
    `
    margin-top: ${props => (props.layoutAdjustmentWhoWeAre ? '0' : '9rem')};
    height: ${props =>
      props.layoutAdjustmentWhoWeAre ? '45.5625rem' : '35.625rem'};
    background-image: linear-gradient(to right, #1d52a3, #45a7df);
   `};

  @media ${device.laptop} {
    padding: ${props => !props.codeOfConduct && '1.875rem 0'};
    margin: ${props => (props.codeOfConduct ? '0 -50px' : '0 -20px')};
    margin-bottom: ${props =>
      props.layoutAdjustmentTimeLine ? '0' : !props.codeOfConduct && '8rem'};
    width: ${props => (!props.codeOfConduct ? 'auto' : '100%')};
    ${'' /* margin: 0 -20px; */}
    height: ${props => (props.codeOfConduct ? '32rem' : '38rem')};
    background: ${props => props.codeOfConduct && 'none'};
    height: ${props =>
      props.isAdjustGridLargeLaptop ? props.isAdjustGridLargeLaptop : '50rem'};
  }

  @media ${device.tablet} {
    ${'' /* margin: ${props => props.codeOfConduct && '0 -50px'}; */}
    margin-top: ${props =>
      props.codeOfConduct && props.layoutAdjustmentWhoWeAre
        ? '0'
        : props.codeOfConduct && '3rem'};
    height: ${props =>
      props.isAdjustGridLarge ? props.isAdjustGridLarge : '50rem'};
  }

  @media ${device.mobile} {
    margin: 0 -20px;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: ${props =>
      props.marginBottomGridLargemobile && props.marginBottomGridLargemobile};
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop) => {
  const components = page[0]?.components ?? [];
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);
  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      {components.length >= 1 && Components(components[0])}
      {components.length >= 1 && Components(components[1])}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} borderBottom paddingTop>
          <OurStory // Nossa história
            markdown={getMarkdown(page, 'ckq9znn8g7tih0b79dy4hhygl', true)}
            fragments={getMarkdown(page, 'ckq9znn8g7tih0b79dy4hhygl')}
            asset={getMarkdown(page, 'ckq9znn8g7tih0b79dy4hhygl', false, true)}
            textMaxWidth="570px"
            contentMarginLeftDesk="14px"
            textWidthDesk="100%"
            imgAdjustWidth="574"
            ImgAdjustHeight="380"
            imgAdjustWidthmobile="100%"
            paddingTopMobile="30px"
            marginbottomImgMobile="30px"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom paddingTop>
          <OurStory // O Início
            markdown={getMarkdown(page, 'ckq9zov7s7qqd0b22sx7t4iza', true)}
            fragments={getMarkdown(page, 'ckq9zov7s7qqd0b22sx7t4iza')}
            asset={getMarkdown(page, 'ckq9zov7s7qqd0b22sx7t4iza', false, true)}
            textMaxWidth="570px"
            contentMarginLeftDesk="14px"
            textWidthDesk="100%"
            imgAdjustWidth="574"
            ImgAdjustHeight="380"
            imgAdjustWidthmobile="100%"
            paddingTopMobile="30px"
            marginbottomImgMobile="30px"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom paddingTop>
          <OurStory // Projeto adiado
            markdown={getMarkdown(page, 'ckq9zppb47rqt0b22vudxdtjd', true)}
            fragments={getMarkdown(page, 'ckq9zppb47rqt0b22vudxdtjd')}
            asset={getMarkdown(page, 'ckq9zppb47rqt0b22vudxdtjd', false, true)}
            textMaxWidth="570px"
            contentMarginLeftDesk="14px"
            textWidthDesk="100%"
            imgAdjustWidth="574"
            ImgAdjustHeight="380"
            imgAdjustWidthmobile="100%"
            paddingTopMobile="30px"
            marginbottomImgMobile="30px"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom paddingTop>
          <GridPageWhoWeAre
            classNamesTitle="a-retomada" // A retomada
            markdown={getMarkdown(page, 'ckq9zthvs7yqu0b79y6566o8y', true)}
            fragments={getMarkdown(page, 'ckq9zthvs7yqu0b79y6566o8y')}
            asset={getMarkdown(page, 'ckq9zthvs7yqu0b79y6566o8y', false, true)}
            resumed
            imgAdjustHeight="658px"
            textWidthDesk="90%"
            imgAdjustWidthmobile="100%"
            marginbottomImgMobile="30px"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom paddingTop>
          <OurStory // Ideais
            markdown={getMarkdown(page, 'ckq9ztzmo7wct0b221q07tdcr', true)}
            fragments={getMarkdown(page, 'ckq9ztzmo7wct0b221q07tdcr')}
            asset={getMarkdown(page, 'ckq9ztzmo7wct0b221q07tdcr', false, true)}
            contentMarginLeftDesk="14px"
            textWidthDesk="100%"
            imgAdjustWidth="574"
            ImgAdjustHeight="380"
            imgAdjustWidthmobile="100%"
            paddingTopMobile="30px"
            marginbottomImgMobile="30px"
          />
        </Part>
        <Part
          gridColumn={'2 / -2'}
          borderBottom
          paddingTop
          notPaddingTop
          paddingBottomDesk="70px"
        >
          <GridPageWhoWeAre // Ensino e pesquisa
            classNamesTitle="ensino-e-pesquisa"
            markdown={getMarkdown(page, 'ckq9zuzw87xe30b22yyuy98xm', true)}
            fragments={getMarkdown(page, 'ckq9zuzw87xe30b22yyuy98xm')}
            asset={getMarkdown(page, 'ckq9zuzw87xe30b22yyuy98xm', false, true)}
            resumed
            gridCostumized
            imgAdjustHeight="658px"
            imgAdjustWidth="1170px"
            textWidthDesk="100%"
            isMarginTopContainerGridText
            imgAdjustWidthmobile="100%"
            marginbottomImgMobile="30px"
            marginGrindContainer="70px"
            isPageQuemsomos
          />
        </Part>
        <Part gridColumn={'2 / -2'} paddingTop paddingBottomDesk="1.875rem">
          <GridPageWhoWeAre // Responsabilidade social
            classNamesTitle="responsabilidade-social"
            markdown={getMarkdown(page, 'ckq9zwetk7xbs0a79knl25jok', true)}
            fragments={getMarkdown(page, 'ckq9zwetk7xbs0a79knl25jok')}
            asset={getMarkdown(page, 'ckq9zwetk7xbs0a79knl25jok', false, true)}
            resumed
            imgAdjustHeight="658px"
            textWidthDesk="100%"
            imgAdjustWidthmobile="100%"
            marginbottomImgMobile="30px"
            marginGrindContainer="70px"
          />
        </Part>
        <GridLarge
          backgroundColor="#d3d3d320"
          layoutAdjustmentTimeLine
          isAdjustGridLarge="40rem"
          isAdjustGridLargeLaptop="45rem"
        >
          <Part // Linha do tempo
            gridColumn={'2 / -2'}
            paddingBottom="0"
            widthLap="100%"
          >
            <CharitySocietyAboutUs
              markdown={getMarkdown(page, 'ckqa0510887qk0b79qvmya1i6', true)}
              fragments={getMarkdown(page, 'ckqa0510887qk0b79qvmya1i6')}
              isShowing
              whoWeAre
              isAdjustWidthmobileImg="270px !important"
            />
          </Part>
        </GridLarge>
        <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
          {components.length >= 2 && Components(components[2])}
        </Part>
      </GridContainer>
    </>
  );
};

const QuemSomos = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckqa095xc894n0b2249a7vxpz', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "ckqa09cvc88jn0a792p7atco7" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                    }
                  }
                  ... on GraphCMS_HighlightContent {
                    id
                    name
                    type
                    title
                    customTitle
                    description
                    images {
                      handle
                      width
                      height
                      url
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <QuemSomos page={response.gcms.site.pages} />;
      }}
    />
  );
};
