// Libs
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Carousel from 'react-elastic-carousel';

//Style Carousel Personalizado
import {
  ContainerCarousel,
  ContainerCustomizedCardCarousel,
} from './styleCharitySocietyAboutUs';

// Components
import { device } from '../device';

// Styles
const Container = styled.section`
  padding: ${props =>
    props.whoWeAre ? '0 0 2.875rem 0' : '4.375rem 0 2.875rem'};
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  border-bottom: ${props => (props.isLast ? '0.063rem solid #C6C8CC' : 'none')};

  @media ${device.laptop} {
    padding: ${props => (props.whoWeAre ? '0' : '1.875rem 0')};
    display: block;
    width: ${props => props.whoWeAre && '100%'};
  }

  @media ${device.mobile} {
    width: ${props => props.whoWeAre && '90%'};
  }

  > p {
    margin-bottom: 1.875rem;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;
    letter-spacing: 0px;
    a {
      color: #45a7df;
      :visited {
        color: #45a7df;
      }
    }
    @media ${device.mobile} {
      font-size: 0.875rem;
    }
  }
`;

const ContainerDescription = styled.div`
  margin-bottom: 50px;
  display: flex;
  grid-column: 2 / -2;
  position: absolute;

  ${props =>
    props.whoWeAre &&
    `
    margin-left: 0rem;
    width: 100%;
    `};
  @media ${device.laptop} {
    width: 50%;
  }
  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.laptop} {
    ${props => props.whoWeAre && 'margin-left: 0'};
    margin-bottom: ${props => (props.whoWeAre ? '0' : '1.875rem')};
    flex-direction: column;
  }
`;

const Text = styled.span`
  margin-right: ${props => props.isTitle && '1.875rem'};
  width: 570px;

  > :last-child {
    margin-bottom: ${props => props.isBottom && '0'};
  }

  > p {
    margin-bottom: ${props => props.isBottom && '1.875rem'};
    width: ${props => (props.isShort ? '17.5rem' : '34.375rem')};
    font-size: ${props => props.isTitle && '1.5rem'};
    line-height: 26px;

    @media ${device.laptop} {
      margin-bottom: 20px;
      width: 100%;
      line-height: 22px;
    }
    @media ${device.mobile} {
      width: ${props => (props.isShort ? '13.4375rem' : '100%')};
      font-size: ${props => (props.isTitle ? '1.125rem' : '0.875rem')};
    }
  }

  @media ${device.laptop} {
    width: 100%;
    font-size: 0.875rem;
  }
`;

const ContainerMarkdown = styled.div`
  position: relative;
  display: flex;
  grid-column: 1 / -1;
  overflow-x: scroll;
  padding-top: 5rem;

  @media ${device.laptop} {
    padding-bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100vw;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  > p {
    margin-right: 1.875rem;
    width: 35.625rem;
    display: inline-table;

    > img {
      margin-bottom: 0.625rem;
      height: 23.75rem;
    }
    > em {
      color: #8f9194;
      font-size: 0.75rem;
      font-style: normal;
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    li {
      > img {
        width: 570px;
        height: 380px;
        object-fit: cover;

        @media ${device.laptop} {
          max-width: 380px;
        }

        @media ${device.mobile} {
          max-width: 270px;
          max-height: 180px;
        }
      }
    }
  }
  .rec-carousel-wrapper {
    width: 100%;
  }
  .rec.rec-slider-container {
    margin: 0;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding-left: 40px;
  }

  .rec.rec-arrow[disabled] {
    opacity: 0;
    cursor: default;
  }

  .rec.rec-arrow-left {
    position: absolute;
    top: 0rem;
    right: 9.75rem;
    background: #ffffff;
    color: #45a7df;
    box-shadow: none;
    transition: opacity 0.3s;
    line-height: 0;
    font-size: 16px;
    width: 34px;
    min-width: 34px;
    height: 34px;

    @media ${device.laptop} {
      bottom: 3rem;
      right: 17.75rem;
    }
  }

  .rec.rec-arrow-right {
    position: absolute;
    top: 0rem;
    right: 7rem;
    background: #ffffff;
    box-shadow: none;
    color: #45a7df;
    transition: opacity 0.3s;
    line-height: 0;
    font-size: 16px;
    width: 34px;
    min-width: 34px;
    height: 34px;

    @media ${device.laptop} {
      bottom: 3rem;
      right: 13.75rem;
    }
  }

  .rec.rec-arrow-left:hover {
    filter: brightness(0.9);
    background: #c4c4c4;
  }

  .rec.rec-arrow-right:hover {
    filter: brightness(0.9);
    background: #c4c4c4;
  }

  .rec-item-wrapper {
    width: 570px !important;
    position: relative;
    font-size: 3.375rem;
    font-weight: bold;
    line-height: 3.625rem;

    @media ${device.laptop} {
      width: 400px;
    }

    @media ${device.mobile} {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }

    strong::before {
      content: '';
      position: absolute;
      top: 26.2rem;
      left: 9rem;
      width: 59%;
      height: 0.61px;
      background: #c4c4c4;

      @media ${device.mobile} {
        top: 320px;
        left: 75px;
        width: 62.5%;
        height: 0.61px;
        background: #c6c8cc;
      }
      @media ${device.mobile} {
        top: 202px;
        left: 75px;
        width: 57%;
        height: 0.61px;
        background: #c6c8cc;
      }

      @media (max-width: 376px) {
        top: 200px;
        width: 62.5%;
      }

      @media (max-width: 360px) {
        top: 200px;
        width: 60.5%;
      }

      @media ${device.mobile320} {
        top: 165px;
        width: 55%;
      }
    }

    strong {
      display: block;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.625rem;

      @media ${device.mobile} {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    em {
      display: block;
      font-size: 1rem;
      font-style: normal;
      font-weight: normal;
      line-height: 1.625rem;
      margin-top: -2.8rem;
      height: 100px;

      @media ${device.laptop} {
        height: 100px;
      }
      @media ${device.mobile} {
        height: 155px;
        margin-top: -1.8rem;
      }
      @media ${device.mobile} {
        height: 170px;
      }
      @media ${device.mobile320} {
        height: 240px;
      }
    }
  }
`;

const BallSlider = styled.div`
  display: flex;
  transform: ${props =>
    props.whoWeAre ? 'translate(0, 0)' : 'translate(-560px, 433px)'};
  position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  transform: ${props =>
    props.whoWeAre ? 'translate(0,0)' : 'translate(-560px, 433px)'};
  width: ${props => (props.whoWeAre ? '100%' : 'auto')};
  position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  margin-top: 0;
  margin-left: 0;

  @media ${device.laptop} {
    display: ${props => (props.whoWeAre ? 'none' : 'block')};
    transform: ${props =>
      props.whoWeAre ? 'translate(0,0)' : 'translate(-560px, 433px)'};
    width: ${props => (props.whoWeAre ? '100%' : 'auto')};
    position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
    margin-top: 34px;
    margin-bottom: 30px;
  }
`;

const Balls = styled.button`
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 1.875rem;
  background-color: ${({ active }) => (active ? '#C6C8CC' : '#FFF')};
  border: 1px solid #c6c8cc;
  cursor: pointer;
  outline: none;
  :active {
    background: #c6c8cc;
    border: unset;
  }
`;

const CharitySocietyAboutUs = ({
  markdown,
  fragments,
  whoWeAre,
  isAdjustWidthmobileImg,
}) => {
  const { singletexts } = fragments?.length > 0 ? fragments[0] : fragments;

  const [scroll, setScroll] = useState(false);

  const data = [markdown];

  const cutData = data[0].split(' x)');

  const arrayFormat = cutData.concat();
  arrayFormat.pop();

  const [isTablet, setTablet] = useState(false);

  useEffect(() => {
    const windowAvaliableWidth = window.screen.availWidth;
    if (typeof window !== 'undefined' && windowAvaliableWidth <= 1024) {
      setTablet(true);
    }
  }, []);

  const carouselBreakPoints = [
    {
      width: 1,
      itemsToShow: 1.2,
      itemsToScroll: 1,
      itemPadding: [0, 0, 0, 0],
      showArrows: false,
      pagination: false,
    },

    {
      width: 1024,
      itemsToShow: 2.1,
      itemsToScroll: 1,
      itemPadding: [0, 30, 0, 0],
      showArrows: true,
      pagination: false,
    },
  ];

  function renderCarousel() {
    return (
      <>
        {isTablet ? (
          <ContainerCarousel>
            <ContainerCustomizedCardCarousel
              isAdjustWidthmobileImg={isAdjustWidthmobileImg}
            >
              {arrayFormat.map(item => (
                <ReactMarkdown
                  className="container-customize"
                  key={item}
                  children={item}
                />
              ))}
            </ContainerCustomizedCardCarousel>
          </ContainerCarousel>
        ) : (
          <Carousel
            breakPoints={carouselBreakPoints}
            renderPagination={({ pages, activePage, onClick }) => {
              return (
                <BallSlider whoWeAre={whoWeAre}>
                  {pages.map(page => {
                    const isActivePage = activePage === page;
                    return (
                      <>
                        <Balls
                          key={page}
                          onClick={() => onClick(page)}
                          active={isActivePage}
                        />
                      </>
                    );
                  })}
                </BallSlider>
              );
            }}
          >
            {arrayFormat.map(item => (
              <ReactMarkdown key={item} children={item} />
            ))}
          </Carousel>
        )}
      </>
    );
  }

  return (
    <Container whoWeAre={whoWeAre}>
      {singletexts?.length > 0 && (
        <ContainerDescription whoWeAre={whoWeAre}>
          <Text isShort isTitle>
            <p>{singletexts[0]}</p>
          </Text>
          <Text isBottom>
            <p>{singletexts[1]}</p>
            <p>{singletexts[2]}</p>
          </Text>
        </ContainerDescription>
      )}
      <ContainerMarkdown
        onScroll={() => !scroll && setScroll(true)}
        scrolled={scroll}
        whoWeAre={whoWeAre}
      >
        {renderCarousel()}
      </ContainerMarkdown>
    </Container>
  );
};

export default CharitySocietyAboutUs;
