import React, { Fragment } from 'react';
import GraphImg from 'graphcms-image';
import {
  ParentGridContainer,
  GridContainerChildImage,
  GridContainerMainText,
  MainContainerTitle,
} from './style';

import { ContainerGrid, TextAreaGrid } from './styleGridCostumized.js';
import ReactMarkdown from 'react-markdown';

export function GridPageWhoWeAre({
  markdown,
  asset,
  fragments,
  resumed,
  contentMarginLeftDesk,
  textWidthDesk,
  classNamesTitle,
  imgAdjustWidthmobile,
  marginbottomImgMobile,
  imageHeight,
  imgAdjustHeight,
  imgAdjustWidth,
  marginGrindContainer,
  isEnsinoEPesquisa,
  gridCostumized,
  isMarginTopContainerGridText,
  gridTemplateRows,
  isMarkdown,
  GridContainerMainTextMarginTop,
  MainContainerTitleMarginTop,
  gridColumnGap,
  isDoctorSupport,
  isEspecificCustomized,
}) {
  const title = fragments && fragments[0].singletexts;
  const data = [markdown];
  const arrayInforTheResumption = data[0].split(' x)');
  arrayInforTheResumption.pop();

  return (
    <ParentGridContainer isDoctorSupport={isDoctorSupport}>
      <GridContainerChildImage
        imgAdjustWidthmobile={imgAdjustWidthmobile}
        imgAdjustHeight={imgAdjustHeight}
        imgAdjustWidth={imgAdjustWidth}
        marginbottomImgMobile={marginbottomImgMobile}
        resumed={resumed}
        imageWidth={asset && asset.width}
        imageHeight={asset && asset.height}
        isDoctorSupport={isDoctorSupport}
      >
        <MainContainerTitle
          isDoctorSupport={isDoctorSupport}
          MainContainerTitleMarginTop={MainContainerTitleMarginTop}
        >
          {title}
        </MainContainerTitle>
        {asset ? (
          <GraphImg
            isPageQuemsomos
            image={asset}
            className="ImgComponent"
            alt="ImgComponent"
            fadeIn={true}
            withWebp={true}
          />
        ) : isEnsinoEPesquisa ? (
          <></>
        ) : (
          <> </>
        )}
      </GridContainerChildImage>
      {gridCostumized ? (
        <ContainerGrid
          isMarginTopContainerGridText={isMarginTopContainerGridText}
          isDoctorSupport={isDoctorSupport}
        >
          {arrayInforTheResumption &&
            arrayInforTheResumption.length > 0 &&
            arrayInforTheResumption.map((el, index) => (
              <Fragment key={index}>
                <TextAreaGrid
                  isEspecificCustomized={isEspecificCustomized}
                  isDoctorSupport={isDoctorSupport}
                >
                  <ReactMarkdown children={el} />
                </TextAreaGrid>
              </Fragment>
            ))}
        </ContainerGrid>
      ) : (
        <GridContainerMainText
          isEnsinoEPesquisa={isEnsinoEPesquisa}
          marginGrindContainer={marginGrindContainer}
          className={classNamesTitle}
          resumed={resumed}
          contentMarginLeftDesk={contentMarginLeftDesk}
          textWidthDesk={textWidthDesk}
          gridTemplateRows={gridTemplateRows}
          GridContainerMainTextMarginTop={GridContainerMainTextMarginTop}
          gridColumnGap={gridColumnGap}
        >
          {!isMarkdown &&
            arrayInforTheResumption.map(function (item, indice) {
              return (
                <p className={`${classNamesTitle}-${indice + 1}`} key={indice}>
                  {item}
                </p>
              );
            })}
          {isMarkdown &&
            arrayInforTheResumption.map(function (item, indice) {
              return (
                <ReactMarkdown
                  key={indice}
                  className={`${classNamesTitle}-${indice + 1}`}
                  children={item}
                />
              );
            })}
        </GridContainerMainText>
      )}
    </ParentGridContainer>
  );
}
