import styled from 'styled-components';
import { device } from '../device';

export const ContainerCarousel = styled.div`
  display: flex;
`;

export const ContainerCustomizedCardCarousel = styled.div`
  display: flex;
  flex-direction: row;

  .container-customize {
    margin-right: 1.875rem;

    @media ${device.mobile320} {
      margin-bottom: 2.5rem;
    }
  }

  .container-customize:last-child {
    margin-right: 0;
    padding-right: 4.375rem;

    @media ${device.mobile} {
      padding-right: 0;
    }
  }

  ul li > img {
    width: ${props =>
      props.isAdjustWidthmobileImg && props.isAdjustWidthmobileImg};
    margin-bottom: 1.875rem;
  }

  ul {
    line-height: 0;
  }

  ul > li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #231f20;
    position: relative;
  }

  ul li > strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-bottom: 0.625rem;

    :before {
      content: '';
      position: absolute;
      top: 14.0625rem;
      left: 4.6875rem;
      width: 70%;
      height: 0.61px;
      background: #c6c8cc;
    }
  }

  ul > li > em {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
`;
