import styled from 'styled-components';
import { device } from 'components/device';

export const ParentGridContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    padding-right: ${props => props.isDoctorSupport && '1.25rem'};
    padding-left: ${props => props.isDoctorSupport && '1.25rem'};
  }
  @media ${device.mobile320} {
    padding-right: ${props => props.isDoctorSupport && '2.5rem'};
    padding-left: ${props => props.isDoctorSupport && '2.5rem'};
  }

  h2 {
    @media ${device.mobile} {
      margin-top: ${props => (props.isDoctorSupport ? '1.875rem' : '1.875rem')};
    }
  }
`;

export const MainContainerTitle = styled.h2`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #231f20;
  margin-top: ${props =>
    props.MainContainerTitleMarginTop && props.MainContainerTitleMarginTop};
  margin-bottom: ${props => (props.isDoctorSupport ? '3.125rem' : '3.125rem')};
  padding-top: ${props => props.isDoctorSupport && '4.375rem'};
  width: ${props => props.isDoctorSupport && '35%'};

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
    padding-top: ${props => props.isDoctorSupport && '1.875rem'};
    font-size: 1.125rem;
  }

  @media ${device.tablet} {
    margin-top: ${props => (props.isDoctorSupport ? '0' : '1.875rem')};
    width: ${props => props.isDoctorSupport && '40%'};
  }

  @media ${device.mobile} {
    margin-top: ${props => props.isDoctorSupport && '0'};
    margin-bottom: ${props => props.isDoctorSupport && '0'};
    padding-top: ${props => props.isDoctorSupport && '0'};
    width: ${props => props.isDoctorSupport && '90%'};
    order: ${props => props.isDoctorSupport && '1'};
  }
`;
export const GridContainerMainText = styled.div`
  margin-top: ${props => (props.isEnsinoEPesquisa ? '0' : '4.7rem')};
  margin-top: ${props =>
    props.GridContainerMainTextMarginTop &&
    props.GridContainerMainTextMarginTop};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 5.2rem 8.5rem;
  grid-template-rows: ${props =>
    props.gridTemplateRows && props.gridTemplateRows};
  grid-column-gap: ${props => props.gridColumnGap && props.gridColumnGap};
  grid-column-gap: 0.625rem;
  margin-left: ${props =>
    props.contentMarginLeftDesk
      ? props.contentMarginLeftDesk
      : !props.resumed && '5.3125rem'};
  margin-bottom: ${props =>
    props.marginGrindContainer && props.marginGrindContainer};

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    margin-top: 0;
    margin-left: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }
  /**Trecho (A-Retomada) */
  /**Primeira coluna Inicio */
  .a-retomada-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .a-retomada-2 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .a-retomada-3 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  /**Primeira coluna Fim */

  /**Segunda coluna  Inicio*/

  .a-retomada-4 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 1;
  }
  .a-retomada-5 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 2;
  }
  .a-retomada-6 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 3;
  }
  /**Segunda coluna Fim*/

  /**Trecho (A-Retomada) */
  /**Primeira coluna Inicio */
  .responsabilidade-social-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .responsabilidade-social-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    margin-top: 80px;

    @media ${device.laptop} {
      margin-top: 0;
    }
  }

  /**Segunda coluna  Inicio*/
  .responsabilidade-social-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 1;
  }
  .responsabilidade-social-4 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 2;
    margin-top: 5px;
  }

  /**Primeira coluna ensino e pesquisa */
  .sobre-ensino-e-pesquisa-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .sobre-ensino-e-pesquisa-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row: 2;
    row-gap: 0.625rem;
  }

  /**Segunda coluna ensino e pesquisa */
  .sobre-ensino-e-pesquisa-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 1;
  }
  .sobre-ensino-e-pesquisa-4 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 2;
  }
  .sobre-ensino-e-pesquisa-5 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 3;
  }
  .sobre-ensino-e-pesquisa-6 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 4;
  }

  /**Primeira coluna saude populacional */
  .saude-populacional-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .saude-populacional-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row: 2;
    row-gap: 30px;
    margin-top: -35px;
    @media ${device.laptop} {
      margin-top: 0;
    }
  }

  /**Segunda coluna saude populacional */
  .saude-populacional-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 1;
  }
  .saude-populacional-4 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 2;
  }
  .saude-populacional-5 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 3;
  }
  .saude-populacional-6 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 4;
  }

  @media ${device.laptop} {
    .saude-populacional-2 {
      margin-top: 0;
    }
    .saude-populacional-4 {
      margin-top: 0;
    }
  }
  [class^='saude-populacional-'] {
    @media ${device.laptop} {
      margin-bottom: 20px;

      &:last-child {
        padding-bottom: 20px;
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;
    margin-bottom: 2rem;
    width: ${props => (props.textWidthDesk ? props.textWidthDesk : '85%')};

    @media ${device.laptop} {
      margin-left: 0;
      font-size: 0.875rem;
    }

    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

export const GridContainerChildImage = styled.div`
  width: ${props => (props.resumed ? '100%' : '50%')};

  @media ${device.laptop} {
    width: ${props => props.imgAdjustWidthmobile && props.imgAdjustWidthmobile};
    margin-bottom: ${props =>
      props.marginbottomImgMobile && props.marginbottomImgMobile};
  }

  @media ${device.mobile} {
    display: ${props => props.isDoctorSupport && 'flex'};
    flex-direction: ${props => props.isDoctorSupport && 'column'};
  }

  .ImgComponent {
    width: ${props =>
      props.imgAdjustWidth ? props.imgAdjustWidth : `${props.imageWidth}px`};
    height: ${props =>
      props.imgAdjustHeight ? props.imgAdjustHeight : `${props.imageHeight}px`};

    @media ${device.laptop} {
      display: flex;
      width: 100%;
      height: ${props => props.isDoctorSupport && '350px'};
    }

    @media ${device.tablet} {
      height: ${props => props.isDoctorSupport && '260px'};
    }

    @media ${device.mobile} {
      height: ${props => (props.isDoctorSupport ? '120px' : '223px')};
      margin-top: ${props => props.isDoctorSupport && '1.25rem'};
    }
  }
  .image {
    width: ${props => (props.resumed ? '100%' : '570px')};
    height: 380px;
    background-color: #c4c4c4;
    margin-right: 5.3125rem;

    @media ${device.laptop} {
      display: none;
    }
  }
`;
