import styled from 'styled-components';
import { device } from 'components/device';

export const ContainerGrid = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${props => props.isMarginTopContainerGridText && '3.125rem'};
  padding-top: ${props => props.isDoctorSupport && '3.125rem'};
  padding-bottom: ${props => props.isDoctorSupport && '3.125rem'};

  @media ${device.mobile} {
    flex-direction: column;
    padding-top: ${props => props.isMarginTopContainerGridText && '0'};
    padding-top: ${props => props.isDoctorSupport && '1.875rem'};
    padding-bottom: ${props => props.isDoctorSupport && '1.875rem'};
  }
`;

export const TextAreaGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;

  :first-child {
    margin-right: 30px;
  }

  > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #231f20;
    margin-bottom: 35px;

    @media ${device.mobile} {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
    }

    a {
      color: #45a7df;
    }
  }

  :nth-child(1) {
    p:nth-child(1) {
      margin-bottom: 30px;

      @media ${device.mobile} {
        margin-bottom: ${props => (props.isDoctorSupport ? '25px' : '30px')};
      }
    }

    p:last-of-type {
      margin-bottom: 0;

      @media ${device.mobile} {
        margin-bottom: ${props => (props.isDoctorSupport ? '25px' : '30px')};
      }
    }
  }

  :nth-child(2) {
    p:nth-child(1) {
      margin-bottom: 30px;

      @media ${device.mobile} {
        margin-bottom: ${props => (props.isDoctorSupport ? '25px' : '30px')};
      }
    }
    p:nth-child(2) {
      margin-bottom: ${props =>
        props.isEspecificCustomized ? '51px' : '25px'};

      @media ${device.mobile} {
        margin-bottom: ${props => (props.isDoctorSupport ? '25px' : '25px')};
      }
    }
    p:nth-child(3) {
      margin-bottom: 25px;
      @media ${device.mobile} {
        margin-bottom: ${props => (props.isDoctorSupport ? '25px' : '25px')};
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
`;
